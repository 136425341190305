import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-if-home',
  templateUrl: './if-home.component.html',
  styleUrls: ['./if-home.component.scss']
})
export class IfHomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
